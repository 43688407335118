import React,{ Component }     from 'react';
import { FRONT_SWATCH_TYPES }  from '@frontend/constants.js';
import { connect }             from 'react-redux'
import { openSwatchesModal,
          closeSwatchesModal }  from '@frontend/actions/swatches-modal'
import { setSelectedLabel}      from '@frontend/actions/theme';
import EzComponent              from '@frontend/components/EzComponent';
import { LazyLoadComponent }    from 'react-lazy-load-image-component';
import _each                    from 'lodash/each';
import _get                     from 'lodash/get';
import _noop                    from 'lodash/noop';
import _sortBy                  from 'lodash/sortBy';


class SwatchItem extends EzComponent{
  state = {
    selectedValue:'',
    showAll: false,
    scrolled: false,
    visualSelectOptionsVisibility: false
  }
  list = React.createRef();
  boxRef = React.createRef();

  handleScroll = () => {
    let scrolled = window.scrollY > window.innerHeight;
    let showAll  = this.props.showAll;
    if (showAll === true&&scrolled === false) {
      showAll = false;
    }
    this.setState({ scrolled: scrolled, showAll: showAll })
  };


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleSelectChange = (ev)=>{
    const option = {
      option_id:    parseInt(this.props.option.option_id),
      option_value: parseInt(ev.target.value)
    }
    this.setState({selectedValue:ev.target.value});
    this.props.onOptionSelected(option);

  }
  onValueSelected = (value)=>(ev)=>{
    const option = {
      option_id:    parseInt(this.props.option.option_id),
      option_value: parseInt(value.value_id)
    }
    this.setState({selectedValue:value.value_id});
    this.props.onOptionSelected(option);
    if (this.props.setParentSwatchState) {
      this.props.setParentSwatchState(option.option_value)
    }
    this.props.setSelectedLabel(value.label)
  }
  handleVisualSelectedClick = () => {
    this.setState(state => ({visualSelectOptionsVisibility: !state.visualSelectOptionsVisibility}));
  }
  componentDidMount(){
    const { option:{ type,values},product,selectedValue:selectedValueProps = null } = this.props;
    const childProducts      = JSON.parse(product.product_childs);
    let selectedValue, selectedOption;
    window.addEventListener('scroll', this.handleScroll, true);
    
    if(values.length){
      _each(_sortBy(values,['value_id']), (value) => {
        if (value.products.length && childProducts[value.products[0]]){
          selectedValue = value.value_id;
          selectedOption = {
            option_id:    parseInt(this.props.option.option_id),
            option_value: parseInt(selectedValue)
          }
          return false;
        }
      });

      this.setState({selectedValue});
      this.props.onOptionSelected(selectedOption);
    }
    if (selectedValueProps) {
      this.setState({ selectedValue: selectedValueProps });
    }

  }
  onModalValueSelected = (option)=>{
    const { goToTop = false } = this.props;
    this.setState({selectedValue:option.option_value});
    if (goToTop){
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }    
    this.props.onOptionSelected(option);
    this.props.setSelectedLabel(option.label)
  }
  getTypeLabel(){
    const { option: { type } } = this.props;

    return FRONT_SWATCH_TYPES.array[type];
  }
  getIsSwatchSelect(){
    return this.getTypeLabel() === FRONT_SWATCH_TYPES.array[FRONT_SWATCH_TYPES.select];
  }
  getSwatchOptionsComponents(){
    const {
      option = {},
      product,
      storeConfiguration: { visualSwatchBasicUrl }
    }                       = this.props;
    const { selectedValue } = this.state;
    const typeLabel         = this.getTypeLabel();
    const isSwatchSelect    = this.getIsSwatchSelect();
    const childProducts     = JSON.parse(product.product_childs);
    let swatchOptionsComponents = { selectOptions: [], visualOptions: [], visualSelectOptions: { selected: false, options: []}};

    if (typeLabel == FRONT_SWATCH_TYPES.array[FRONT_SWATCH_TYPES.visual]) {
      for (var i = 0; i < option.values.length; i++) {
        if (option.values[i].value.includes('url') == false) {
          option.values[i].value = "url("+visualSwatchBasicUrl+option.values[i].value+")";
        }
      }
    }

    _each(_sortBy(option.values,['value_id']), (value, index)=>{
      if(isSwatchSelect){
        swatchOptionsComponents.selectOptions = [
          ...swatchOptionsComponents.selectOptions,
          <option key={index} className="option-value" value={value.value_id}>{value.label}</option>
        ];
        return;
      }
      if (value && value.products.length && childProducts[value.products[0]]){
        let isInStock = "in-stock-swatch";
        if (childProducts[value.products[0]].stock <= 0) {
          isInStock = "no-stock-swatch";
        }
        let additionalClass = (typeLabel==FRONT_SWATCH_TYPES.array[FRONT_SWATCH_TYPES.visual]) ? " col-1 col-mb-1":"";
        swatchOptionsComponents.visualOptions = [
          ...swatchOptionsComponents.visualOptions,
          this.renderOptionValue(value, _noop, this.onValueSelected(value), additionalClass, isInStock, false)
        ];
        if((typeLabel == FRONT_SWATCH_TYPES.array[FRONT_SWATCH_TYPES.visual])){
          additionalClass = "";
          if(selectedValue === value.value_id){
            swatchOptionsComponents.visualSelectOptions.selected = this.renderOptionValue(value, this.handleVisualSelectedClick, _noop, additionalClass, isInStock, true);
          }
          swatchOptionsComponents.visualSelectOptions.options = [
            ...swatchOptionsComponents.visualSelectOptions.options,
            this.renderOptionValue(value, this.onValueSelected(value), _noop, additionalClass, isInStock, true)
          ];
        }
      }
    });

    return swatchOptionsComponents;
  }

  renderOptionValue(value, wrapperClick, optionClick, wrapperClass, optionClass, renderLabel){
    const { option: { type } }  = this.props;
    const { selectedValue }     = this.state;
    const typeLabel             = this.getTypeLabel();
    let style                   = (typeLabel === FRONT_SWATCH_TYPES.array[FRONT_SWATCH_TYPES.background]) ? { background: value.value } :{};
    if(typeLabel == FRONT_SWATCH_TYPES.array[FRONT_SWATCH_TYPES.visual]){
      style                     = {backgroundImage: value.value};
    }
    return(
      <LazyLoadComponent key={"visual_options_cont_upper" + value.value_id}>
        <div key={`cont${value.value_id}`} className={`option-value-wrapper ${wrapperClass}`} onClick={wrapperClick}>
          <div
            key={value.value_id}
            className={`option-value ${(selectedValue === value.value_id) ? " selected" : ""} ${optionClass}`}
            style={style}
            onClick={optionClick}
          ></div>
          {
            renderLabel &&
            <div className="value-label">{value.label}</div>
          }
        </div>
      </LazyLoadComponent>
    )
  }

  handleShowAllColors()
  {
    const  { option, product } = this.props;
    const  { selectedValue }   = this.state;
    this.props.openSwatchesModal(option, product, this.onModalValueSelected, this.setParentSwatchState, selectedValue);
  }

  setParentSwatchState = (selected_item)  => {
    this.setState({ selectedValue: selected_item.toString() });
  }

  render(){
    const {
      option = {},
      product,
      storeConfiguration: { currencySymbol },
      showAll = false,
      theme
    } = this.props;
    const {
      selectedValue,
      visualSelectOptionsVisibility
    } = this.state;
    const typeLabel = this.getTypeLabel();
    const isSwatchSelect = this.getIsSwatchSelect();
    const swatchOptionsComponents = this.getSwatchOptionsComponents();
    let categoryViewCass = showAll ? 'show-all-values' : 'show-more-link';
    
    return (
      <div className={`swatch-option-wrapper ${option.attribute_code} ${categoryViewCass}`}>
        <div className="swatch-show-more-additional-info">
          <span className="more-colors-title">{product.name}</span>
          <span className="more-colors-subtitle">{product.product_subtitle}</span>
          <span className="more-colors-price">{product.prices.final_price.toFixed(2)} {currencySymbol}</span>
          <span className="pick-your-tone">{this.i18n.t('productSwatchInfo.color')}

            {
              (theme.selectedLabel) &&
              <span className="pick-your-tone-selected-label">{theme.selectedLabel}</span>
            }

          </span>
        </div>
        <div className={`col-group option-values ${isSwatchSelect ?'swatch-select':''}`}>
        {
          isSwatchSelect ?
          <select value={selectedValue} onChange={this.handleSelectChange}>
            { swatchOptionsComponents.selectOptions }
          </select>
          :
          <>
            {
              _get(swatchOptionsComponents.visualOptions,'length') ?
              swatchOptionsComponents.visualOptions 
              :
              <span className="no-colors">
                {this.i18n.t('productSwatchInfo.noColors')}
              </span>
            }
            {	
              (typeLabel == FRONT_SWATCH_TYPES.array[FRONT_SWATCH_TYPES.visual] && !!_get(swatchOptionsComponents.visualOptions, 'length', false)) &&	
              <span className="more-colors" onClick={this.handleShowAllColors.bind(this)} >	
                {this.i18n.t('productSwatchInfo.moreColors')}
              </span>	
            }
          </>
        }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state,theme){
  return {
    storeConfiguration:state.storeConfig,
    theme:state.theme
  }
}

export default connect(mapStateToProps, { openSwatchesModal, closeSwatchesModal, setSelectedLabel})(SwatchItem);
